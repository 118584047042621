import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { addLocaleData, IntlProvider } from 'react-intl'
// Locale data
import enData from 'react-intl/locale-data/en'
import zhData from 'react-intl/locale-data/zh'
// Messages
import en from '../../i18n/en.json'
import zh from '../../i18n/zh.json'
import Footer from '../Footer'
// Components
import Header from '../Header'
// Style
import './modern-normalize.css'
import styles from './style.module.css'

const messages = { en, zh }

addLocaleData([...enData, ...zhData])

const Layout = ({ locale, location, children }) => (
  <IntlProvider locale={locale} messages={messages[locale]}>
    <Fragment>
      {locale === 'en' ? (
        <Helmet>
          <title>Atlas Protocol - Leading Blockchain Advertising & Marketing Platform</title>
          <meta name="description" content="Atlas Interactive - Leading Blockchain Advertising & Marketing Platform,Achieve your marketing goals with " />
          <meta name="keywords" content="blockchain interactive advertising technology, Closing the gap between information and real users.
Keywords：Blockchain Interactive Advertising,Atlas Interactive Advertising,Blockchain Advertising,Blockchain Interactive Advertising & Marketing,blockchain based advertising,atlas smartvoting,atlas smartdrop,Affiliated Marketing,Brand Advertising" />
        </Helmet>
      ) : (
        <Helmet>
            <title>星途协议-首家区块链互动广告营销开放平台</title>
            <meta name="description" content="星途区块链互动广告营销平台,用透明、卓越的区块链互动广告技术实现您的营销目标,打破信息孤岛,获得更多优质关注." />
            <meta name="keywords" content="链上互动广告,星途链上互动广告,区块链广告,链上互动营销,链上原生营销,通证化营销,区块链广告营销,区块链广告技术,区块链互动广告,区块链互动广告营销,星途智能空投,星途智能投票,裂变营销,品牌广告" />
        </Helmet>
      )}
      <Header location={location} />
      <div className={styles.content}>{children}</div>
      <Footer />
    </Fragment>
  </IntlProvider>
)

export default Layout
