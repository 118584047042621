import React from 'react'
import { FormattedMessage } from 'react-intl'
import icon from './address.svg'
import styles from './index.module.css'

const JoinUs = () => (
  <div className={styles.wrapper}>
    <div className={styles.top}>
      <p>
        <FormattedMessage id="joinUs.slogan" />
      </p>
    </div>
    <div className={styles.bottom}>
      <p>
        <FormattedMessage id="joinUs.sendEmail" />
      </p>
      <p style={{ marginTop: 0 }}>
        <FormattedMessage id="joinUs.openPosition" />
      </p>

      <div>
        <h3 className={styles.title}>
          <FormattedMessage id="joinUs.frontend.title" />
        </h3>
        <div className={styles.location}>
          <img src={icon} style={{ width: 10, marginRight: 10 }} />
          <FormattedMessage id="joinUs.location" />
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.duty" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.frontend.duty1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.frontend.duty2" />
          </p>
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.requirement" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.frontend.requirement1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.frontend.requirement2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.frontend.requirement3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.frontend.requirement4" />
          </p>
          <p>
            <FormattedMessage id="joinUs.frontend.requirement5" />
          </p>
          <p>
            <FormattedMessage id="joinUs.frontend.requirement6" />
          </p>
        </div>
        <div className={styles.plus}>
          <h5>
            <FormattedMessage id="joinUs.plus" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.frontend.plus1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.frontend.plus2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.frontend.plus3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.frontend.plus4" />
          </p>
        </div>
      </div>

      <div>
        <h3 className={styles.title}>
          <FormattedMessage id="joinUs.Mobile-end.title" />
        </h3>
        <div className={styles.location}>
          <img src={icon} style={{ width: 10, marginRight: 10 }} />
          <FormattedMessage id="joinUs.location" />
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.duty" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.Mobile-end.duty1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Mobile-end.duty2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Mobile-end.duty3" />
          </p>
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.requirement" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.Mobile-end.requirement1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Mobile-end.requirement2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Mobile-end.requirement3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Mobile-end.requirement4" />
          </p>
        </div>
        <div className={styles.plus}>
          <h5>
            <FormattedMessage id="joinUs.plus" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.Mobile-end.plus1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Mobile-end.plus2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Mobile-end.plus3" />
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.title}>
          <FormattedMessage id="joinUs.Android.title" />
        </h3>
        <div className={styles.location}>
          <img src={icon} style={{ width: 10, marginRight: 10 }} />
          <FormattedMessage id="joinUs.location" />
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.duty" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.Android.duty1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Android.duty2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Android.duty3" />
          </p>
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.requirement" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.Android.requirement1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Android.requirement2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Android.requirement3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Android.requirement4" />
          </p>
        </div>
        <div className={styles.plus}>
          <h5>
            <FormattedMessage id="joinUs.plus" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.Android.plus1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Android.plus2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Android.plus3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Android.plus4" />
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.title}>
          <FormattedMessage id="joinUs.iOS.title" />
        </h3>
        <div className={styles.location}>
          <img src={icon} style={{ width: 10, marginRight: 10 }} />
          <FormattedMessage id="joinUs.location" />
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.duty" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.iOS.duty1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.iOS.duty2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.iOS.duty3" />
          </p>
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.requirement" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.iOS.requirement1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.iOS.requirement2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.iOS.requirement3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.iOS.requirement4" />
          </p>
        </div>
        <div className={styles.plus}>
          <h5>
            <FormattedMessage id="joinUs.plus" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.iOS.plus1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.iOS.plus2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.iOS.plus3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.iOS.plus4" />
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.title}>
          <FormattedMessage id="joinUs.Backend.title" />
        </h3>
        <div className={styles.location}>
          <img src={icon} style={{ width: 10, marginRight: 10 }} />
          <FormattedMessage id="joinUs.location" />
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.requirement" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.Backend.requirement1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Backend.requirement2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Backend.requirement3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Backend.requirement4" />
          </p>
        </div>
        <div className={styles.plus}>
          <h5>
            <FormattedMessage id="joinUs.plus" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.Backend.plus1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Backend.plus2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Backend.plus3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Backend.plus4" />
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.title}>
          <FormattedMessage id="joinUs.Product.title" />
        </h3>
        <div className={styles.location}>
          <img src={icon} style={{ width: 10, marginRight: 10 }} />
          <FormattedMessage id="joinUs.location" />
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.duty" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.Product.duty1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Product.duty2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Product.duty3" />
          </p>
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.requirement" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.Product.requirement1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Product.requirement2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Product.requirement3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Product.requirement4" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Product.requirement5" />
          </p>
        </div>
        <div className={styles.plus}>
          <h5>
            <FormattedMessage id="joinUs.plus" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.Product.plus1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Product.plus2" />
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.title}>
          <FormattedMessage id="joinUs.UI.title" />
        </h3>
        <div className={styles.location}>
          <img src={icon} style={{ width: 10, marginRight: 10 }} />
          <FormattedMessage id="joinUs.location" />
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.duty" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.UI.duty1" />
          </p>
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.requirement" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.UI.requirement1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.UI.requirement2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.UI.requirement3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.UI.requirement4" />
          </p>
        </div>
        <div className={styles.plus}>
          <h5>
            <FormattedMessage id="joinUs.plus" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.UI.plus1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.UI.plus2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.UI.plus3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.UI.plus4" />
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.title}>
          <FormattedMessage id="joinUs.Operation.title" />
        </h3>
        <div className={styles.location}>
          <img src={icon} style={{ width: 10, marginRight: 10 }} />
          <FormattedMessage id="joinUs.location" />
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.duty" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.Operation.duty1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Operation.duty2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Operation.duty3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Operation.duty4" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Operation.duty5" />
          </p>
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.requirement" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.Operation.requirement1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Operation.requirement2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Operation.requirement3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Operation.requirement4" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Operation.requirement5" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Operation.requirement6" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Operation.requirement7" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Operation.requirement8" />
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.title}>
          <FormattedMessage id="joinUs.New media.title" />
        </h3>
        <div className={styles.location}>
          <img src={icon} style={{ width: 10, marginRight: 10 }} />
          <FormattedMessage id="joinUs.location" />
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.duty" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.New media.duty1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.New media.duty2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.New media.duty3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.New media.duty4" />
          </p>
          <p>
            <FormattedMessage id="joinUs.New media.duty5" />
          </p>
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.requirement" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.New media.requirement1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.New media.requirement2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.New media.requirement3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.New media.requirement4" />
          </p>
          <p>
            <FormattedMessage id="joinUs.New media.requirement5" />
          </p>
          <p>
            <FormattedMessage id="joinUs.New media.requirement6" />
          </p>
          <p>
            <FormattedMessage id="joinUs.New media.requirement7" />
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.title}>
          <FormattedMessage id="joinUs.Business.title" />
        </h3>
        <div className={styles.location}>
          <img src={icon} style={{ width: 10, marginRight: 10 }} />
          <FormattedMessage id="joinUs.location" />
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.duty" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.Business.duty1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Business.duty2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Business.duty3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Business.duty4" />
          </p>
        </div>
        <div className={styles.duty}>
          <h5>
            <FormattedMessage id="joinUs.requirement" />
          </h5>
          <p>
            <FormattedMessage id="joinUs.Business.requirement1" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Business.requirement2" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Business.requirement3" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Business.requirement4" />
          </p>
          <p>
            <FormattedMessage id="joinUs.Business.requirement5" />
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default JoinUs
