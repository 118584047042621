import React from 'react'
import JoinUs from '../components/JoinUs'
import Layout from '../components/Layout'

const AboutPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale} location={location}>
    <JoinUs />
  </Layout>
)

export default AboutPage
